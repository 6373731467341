export const regionSchema = [
  {
    id: "id",
    label: "ID",
    required: ["update", "delete"],
    default: "",
    type: "string",
    validation: "string_id",
    skip: ["add"],
  },
  {
    id: "label",
    label: "Region Label",
    required: ["add", "update"],
    default: "",
    type: "string",
    field: "text",
  },
  {
    id: "company_name",
    label: "Company Name",
    required: ["add", "update"],
    default: "",
    type: "string",
    field: "text",
  },
  {
    id: "company_logo",
    label: "Company Logo",
    required: false,
    default: "",
    type: "string",
    field: "image_upload",
  },
  {
    id: "company_code",
    label: "Company Code",
    required: false,
    default: "",
    type: "string",
    field: "text",
  },
  {
    id: "company_address",
    label: "Company Address",
    required: false,
    default: "",
    type: "string",
    field: "textarea",
  },
  {
    id: "company_email",
    label: "Company Email",
    required: false,
    default: "",
    type: "email",
    field: "email",
  },
  {
    id: "company_phone",
    label: "Company Phone No.",
    required: false,
    default: "",
    type: "string",
    field: "text",
  },
  {
    id: "company_fax",
    label: "Company Fax No.",
    required: false,
    default: "",
    type: "string",
    field: "text",
  },
  {
    id: "company_website",
    label: "Company Website",
    required: false,
    default: "",
    type: "string",
    field: "text",
  },
  {
    id: "tax_label",
    label: "Tax Label",
    required: false,
    default: "",
    type: "string",
    field: "text",
  },
  {
    id: "tax_amount",
    label: "Tax Amount",
    required: false,
    default: 0,
    type: "number",
    field: "text_number",
  },
  {
    id: "tax_reg_number_label",
    label: "Tax Registration Label",
    required: false,
    default: "",
    type: "string",
    field: "text",
  },
  {
    id: "tax_reg_number",
    label: "Tax Registration Number",
    required: false,
    default: "",
    type: "string",
    field: "text",
  },
  {
    id: "prefix_quote",
    label: "Quote Prefix",
    required: ["add", "update"],
    default: "",
    type: "string",
    field: "text",
  },
  {
    id: "prefix_invoice",
    label: "Invoice Prefix",
    required: ["add", "update"],
    default: "",
    type: "string",
    field: "text",
  },
  {
    id: "brochure_cover_heading",
    label: "Brochure Cover Heading",
    required: false,
    default: "",
    type: "string",
    field: "textarea",
  },
];
