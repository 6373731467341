/*
This file contains all constants in the app.
*/

const api_env = "live";
export const DEV_MODE = api_env && api_env === "live" ? false : true;
export const SET_READ_LIMITS = api_env && api_env === "dev" ? true : false;

// firebase related
const FIREBASE_SERVE = true;
export const FIREBASE_API = "https://dreamcatcher-crm.firebaseio.com/";
export const FIREBASE_CLOUD_API =
  FIREBASE_SERVE && DEV_MODE
    ? "http://localhost:5000/dreamcatcher-crm/asia-northeast1/"
    : "https://asia-northeast1-dreamcatcher-crm.cloudfunctions.net";
export const FIREBASE_STORAGE_URL =
  "https://firebasestorage.googleapis.com/v0/b/dreamcatcher-crm.appspot.com/o/";
export const LMS_CLOUD_API_URL =
  "https://us-central1-dreamcatcher-lms.cloudfunctions.net/";

// for batch load
export const FIRST_BATCH_LOAD_LIMITS = api_env && api_env === "dev" ? 100 : 500;
export const REST_BATCH_LOAD_LIMITS = 200;

// firestore related
export const FIRESTORE_DEFAULT_PERPAGE = api_env && api_env === "dev" ? 4 : 20;

// list of access rights
export const ACCESS_RIGHTS = [
  { id: "alr", label: "Limited to Assigned Accounts Only", width: 12 },
  { id: "acr", label: "Accounts (Read)", width: 4 },
  { id: "acw", label: "Accounts (Write)", width: 4 },
  { id: "acs", label: "Accounts (Send Email)", width: 4 },
  { id: "ace", label: "Accounts (Admin Edit)", width: 4 },
  { id: "acg", label: "Accounts (Merge)", width: 4 },
  { id: "act", label: "Accounts (Transfer Ownership)", width: 4 },
  { id: "acd", label: "Accounts (Delete)", width: 12 },
  { id: "ctr", label: "Contacts (Read)", width: 4 },
  { id: "ctw", label: "Contacts (Write)", width: 4 },
  { id: "cts", label: "Contacts (Send Email)", width: 4 },
  { id: "cte", label: "Contacts (Admin Edit)", width: 4 },
  { id: "ctg", label: "Contacts (Merge)", width: 4 },
  { id: "cti", label: "Contacts (Import)", width: 4 },
  { id: "ctd", label: "Contacts (Delete)", width: 12 },
  { id: "ptr", label: "Potentials (Read)", width: 4 },
  { id: "ptw", label: "Potentials (Write)", width: 4 },
  { id: "pts", label: "Potentials (Send Email)", width: 4 },
  { id: "ptd", label: "Potentials (Delete)", width: 12 },
  { id: "qtr", label: "Quotes (Read)", width: 4 },
  { id: "qtw", label: "Quotes (Write)", width: 4 },
  { id: "qts", label: "Quotes (Send Email)", width: 4 },
  { id: "qtd", label: "Quotes (Delete)", width: 12 },
  { id: "ivr", label: "Invoices (Read)", width: 4 },
  { id: "ivw", label: "Invoices (Create)", width: 4 },
  { id: "ivm", label: "Invoices (Modify)", width: 4 },
  { id: "ivs", label: "Invoices (Send Email)", width: 4 },
  { id: "ivd", label: "Invoices (Delete)", width: 8 },
  { id: "cnr", label: "Credit Notes (Read)", width: 4 },
  { id: "cnw", label: "Credit Notes (Create)", width: 4 },
  { id: "cnm", label: "Credit Notes (Modify)", width: 4 },
  { id: "cns", label: "Credit Notes (Send Email)", width: 4 },
  { id: "cnd", label: "Credit Notes (Delete)", width: 8 },
  { id: "pdr", label: "Products (Read)", width: 4 },
  { id: "pdw", label: "Products (Write)", width: 4 },
  { id: "pdd", label: "Products (Delete)", width: 4 },
  { id: "etr", label: "Email Templates (Read)", width: 4 },
  { id: "etw", label: "Email Templates (Write)", width: 4 },
  { id: "etd", label: "Email Templates (Delete)", width: 4 },
  { id: "rpr", label: "Reports (Read)", width: 4 },
  { id: "rpw", label: "Reports (Write)", width: 4 },
  { id: "rpd", label: "Reports (Delete)", width: 4 },
  { id: "mtr", label: "Maintenance (Read)", width: 4 },
  { id: "mtw", label: "Maintenance (Write)", width: 4 },
  { id: "mtd", label: "Maintenance (Delete)", width: 4 },
];

// options
export const ENTRIES_OPTIONS = [
  { value: 5, label: "5" },
  { value: 20, label: "20" },
  { value: 40, label: "40" },
  { value: 60, label: "60" },
  { value: 80, label: "80" },
  { value: 100, label: "100" },
  { value: 200, label: "200" },
  { value: 500, label: "500" },
];

export const MONTH_OPTIONS = [
  { value: "01", label: "Jan" },
  { value: "02", label: "Feb" },
  { value: "03", label: "Mar" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "Aug" },
  { value: "09", label: "Sept" },
  { value: "10", label: "Oct" },
  { value: "11", label: "Nov" },
  { value: "12", label: "Dec" },
];

export const GENDER_OPTIONS = [
  { value: "", label: "Select Gender" },
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];
export const YES_NO_OPTIONS = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];
export const YES_NO_OPTIONS_WITH_BLANK = [
  { value: "", label: "Select an Option" },
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];
export const YES_OPTIONS_WITH_BLANK = [
  { value: "", label: "Select an Option" },
  { value: "yes", label: "Yes" },
];

export const PAYMENT_TERMS = [
  { value: "10-days", label: "10 Days" },
  { value: "15-days", label: "15 Days" },
  { value: "30-days", label: "30 Days" },
  { value: "45-days", label: "45 Days" },
  { value: "60-days", label: "60 Days" },
  { value: "90-days", label: "90 Days" },
  { value: "before_the_class", label: "Before the class" },
  {
    value: "7_days_from_invoice_date",
    label: "7 Days from the invoice date",
  },
];

export const CORE_MODULES = [
  {
    value: "accounts",
    label: "Accounts",
    related: ["contacts", "potentials", "quotes", "invoices", "products"],
  },
  {
    value: "contacts",
    label: "Contacts",
    related: ["accounts", "potentials", "quotes", "invoices", "products"],
  },
  {
    value: "potentials",
    label: "Potentials",
    related: ["accounts", "contacts", "quotes", "products"],
  },
  {
    value: "quotes",
    label: "Quotes",
    related: ["accounts", "contacts", "potentials", "invoices", "products"],
  },
  {
    value: "invoices",
    label: "Invoices",
    related: ["accounts", "contacts", "quotes", "products"],
  },
  {
    value: "credit_notes",
    label: "Credit Notes",
    related: ["accounts", "contacts", "quotes", "invoices", "products"],
  },
  {
    value: "products",
    label: "Products",
    related: ["accounts", "contacts", "potentials", "quotes", "invoices"],
  },
  //{ value: 'emails', label: 'emails' }
];

export const CONDITION_OPTIONS_DEFAULT = [
  { value: "equals", label: "Equals" },
  { value: "notequalto", label: "Not equal to" },
  { value: "startswith", label: "Starts with" },
  { value: "endswith", label: "Ends with" },
  { value: "contains", label: "Contains" },
  { value: "doesnotcontains", label: "Does not contains" },
  { value: "isempty", label: "Is empty" },
  { value: "isnotempty", label: "Is not empty" },
];

export const CONDITION_OPTIONS_NUMBER = [
  { value: "equals", label: "Equals" },
  { value: "notequalto", label: "Not equal to" },
  { value: "lessthan", label: "Less than" },
  { value: "greaterthan", label: "Greater than" },
  { value: "lessorequal", label: "Less or equal" },
  { value: "greaterorequal", label: "Greater or equal" },
  { value: "isempty", label: "Is empty" },
  { value: "isnotempty", label: "Is not empty" },
];

export const CONDITION_OPTIONS_TIMESTAMP = [
  { value: "equals", label: "Equals" },
  { value: "notequalto", label: "Not equal to" },
  { value: "before", label: "Before" },
  { value: "after", label: "After" },
  { value: "between", label: "Between" },
  { value: "isempty", label: "Is empty" },
  { value: "isnotempty", label: "Is not empty" },
  { value: "previous-fy", label: "Previous FY" },
  { value: "current-fy", label: "Current FY" },
  { value: "next-fy", label: "Next FY" },
  { value: "previous-fq", label: "Previous FQ" },
  { value: "current-fq", label: "Current FQ" },
  { value: "next-fq", label: "Next FQ" },
  { value: "yesterday", label: "Yesterday" },
  { value: "today", label: "Today" },
  { value: "tomorrow", label: "Tomorrow" },
  { value: "previous-week", label: "Previous Week" },
  { value: "current-week", label: "Current Week" },
  { value: "next-week", label: "Next Week" },
  { value: "previous-month", label: "Previous Month" },
  { value: "current-month", label: "Current Month" },
  { value: "next-month", label: "Next Month" },
  { value: "last-7days", label: "Last 7 days" },
  { value: "last-30days", label: "Last 30 days" },
  { value: "last-60days", label: "Last 60 days" },
  { value: "last-90days", label: "Last 90 days" },
  { value: "last-120days", label: "Last 120 days" },
  { value: "next-7days", label: "Next 7 days" },
  { value: "next-30days", label: "Next 30 days" },
  { value: "next-60days", label: "Next 60 days" },
  { value: "next-90days", label: "Next 90 days" },
  { value: "next-120days", label: "Next 120 days" },
];

// export const defaultEmailTemplate = {
//     html: '<table class=\"c1617\"><tbody style="box-sizing: border-box;"><tr style="box-sizing: border-box;"><td style="box-sizing: border-box; vertical-align: top; margin: 0; padding: 0;"></td></tr></tbody></table>',
//     css: '* { box-sizing: border-box; } body {margin: 0;}body{background-color:#f1f1f1;}*{box-sizing:border-box;}body{margin-top:0px;margin-right:0px;margin-bottom:0px;margin-left:0px;}.c1617{height:100%;margin:10px auto 10px auto;padding:5px 5px 5px 5px;width:100%;max-width:650px;background-color:#ffffff;font-family:Arial, Helvetica, sans-serif;border:1px solid #dddddd;}',
//     js: ''
// };

export const defaultEmailTemplate = {
  html: "",
  css: "",
  js: "",
};
